<template>
  <div class="box">
    <div class="home" v-if="pay_status == 1">
        <div class="home_redPack">
            <div class="word">价值69元有效期延长券X2</div>
            <div class="redPack_num">邀请与被邀请人各送 <span>2</span> 张</div>
            <div class="redPack_tip">邀请好友激活即送</div>
        </div>
        <div class="btn1">
            价值138元  多邀多得
        </div>
    </div>
    <div class="main" v-if="pay_status == 1">
        <div class="getCash">
            <div class="cashTitle">
                <img src="@/assets/images/invite/left_title.png" alt="">
                <div>领现金攻略</div>
                <img src="@/assets/images/invite/right_title.png" alt="">
            </div>
            <div class="cashStep">
                <div class="cashStep1">
                    <div class="pic">
                        <img src="@/assets/images/invite/share.png" alt="">
                    </div>
                    <div class="cashStep1_title">发起分享</div>
                    <div class="cashStep1_tip">分享活动海报</div>
                </div>
                <div class="next"><img src="@/assets/images/invite/next.png" alt=""></div>
                 <div class="cashStep1">
                    <div class="pic">
                        <img src="@/assets/images/invite/invite.png" alt="">
                    </div>
                    <div class="cashStep1_title">好友接受</div>
                    <div class="cashStep1_tip">购买随身WiFi</div>
                </div>
                <div class="next"><img src="@/assets/images/invite/next.png" alt=""></div>
                 <div class="cashStep1">
                    <div class="pic">
                        <img src="@/assets/images/invite/coupon.png" alt="">
                    </div>
                    <div class="cashStep1_title">发放延长券</div>
                    <div class="cashStep1_tip">设备激活发放</div>
                </div>
            </div>
            <div class="shareBtn" @click="shareFriend">
                <img src="@/assets/images/invite/shareIcon.png" alt="">
                <div>分享链接</div>
            </div>
        </div>
        <div class="getCash inviteInfo">
            <div class="cashTitle">
                <img src="@/assets/images/invite/left_title.png" alt="">
                <div>我的邀请</div>
                <img src="@/assets/images/invite/right_title.png" alt="">
            </div>
            <div class="invite_num">
                <div class="invite_profit">
                    <div class="profit_time">
                        <span>{{in_coupon}}</span>张
                    </div>
                    <div class="profit_tip">
                        在路上收益
                    </div>
                </div>
                <div class="invite_profit two">
                    <div class="profit_time">
                        <span>{{su_coupon}}</span>张
                    </div>
                    <div class="profit_tip">
                        累计收益
                    </div>
                </div>
                <div class="invite_profit two">
                    <div class="profit_time">
                        <span>{{success}}</span>人
                    </div>
                    <div class="profit_tip">
                        成功邀请
                    </div>
                </div>
            </div>
            <div class="invitePeople">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                >
                <div :class="[item.invi_status == 1?'inviteBg2 inviteList' : 'inviteBg1 inviteList']" v-for="(item,index) in inviteList">
                    <div class="listLeft">
                        <img :src="item.u_head_img" alt="">
                        <div>{{item.phone}}</div>
                    </div>
                    <div :class="[item.invi_status == 1?'listRightG':'listRight']">
                        {{item.invi_status == 1?'奖励在路上': '奖励已发放'}}
                    </div>
                </div>
                </van-list>
            </div>
        </div>
        <div class="btn3" @click="shareFriend">邀好友
            <img src="@/assets/images/invite/btn3_icon.png" alt="">
            领福利</div>
    </div>
    <div class="box2" v-if="pay_status == 0">
        <div>活动未开始</div>
    </div>
    <!-- <van-overlay :show="show" @click="show = false">
        <div class="wrapper" @click.stop>
            活动未开始
        </div>
    </van-overlay> -->
  </div>
</template>

<script>
var url = window.location.href; //获取当前url
if (url.indexOf('?') > -1){
    var cs = url.split("?")[1]; //获取?之后的参数字符串
    var cs_arr = cs.split("&"); //参数字符串分割为数组
    var cs={};
    for(var i =0; i<cs_arr.length;i++){
        cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
    }
}else{
    // console.log('没有数据');
    
}

import {getInviteInfo} from '@/untils/api.js'
export default {
    data(){
        return{
            page:1,
            limit:5,
            totalPage:0,
            inviteList:[],//邀请列表
            in_coupon:0,//在路上收益
            su_coupon:0,//累计收益
            success:0,//成功邀请人数
            loading: false,
            finished: false,
            pay_status:'',
            show:false,//z遮罩层
        }
    },
    mounted(){
        
        if(this.pay_status == 1){
          const selectWrap = document.querySelector ('.invitePeople')
            selectWrap.addEventListener ('scroll', this.scrollLoadMore);  
        }else{
            // this.show = true    
        }
        
        this.getInviteInfo()
    },
    methods:{
        //滚动加载
        scrollLoadMore(){
            let scrollWrap = document.querySelector ('.invitePeople');
            var currentScrollTop = scrollWrap.scrollTop;
            var currentOffsetHeight = scrollWrap.scrollHeight;
            var currentClientHeight = scrollWrap.clientHeight;
            if ((currentScrollTop + currentClientHeight == currentOffsetHeight)){
                if(this.page <= this.totalPage){
                    this.loading = true
                    this.page = this.page + 1
                    this.getInviteInfo()
                }else{
                    this.loading = false
                    this.finished =true
                }
            }
        },
        //邀请信息
        getInviteInfo(){
            if(url.indexOf('?') > -1){
                let data = {
                    phone:cs.phone,
                    page:this.page,
                    limit:this.limit,
                }
                this.loading = true
                getInviteInfo({data}).then(res =>{
                    if(res.data.code == 1){
                        this.loading = false
                        this.totalPage = Math.ceil(res.data.data.list.total/this.limit)
                        this.in_coupon = res.data.data.in_coupon
                        this.su_coupon = res.data.data.su_coupon
                        this.success = res.data.data.success
                        this.pay_status = res.data.data.pay_status
                        if(this.page == this.totalPage){
                            this.loading = false
                            this.finished =true  
                        }
                        if(res.data.data.list.data.length == 0){
                            this.loading = false
                            this.finished =true 
                        }
                        this.inviteList = [...this.inviteList, ...res.data.data.list.data]
                    }else{
                        this.loading = false
                        this.finished =true  
                        this.$toast(res.data.msg)
                    }
                })
            }else{
                this.$toast.fail("参数错误")
                this.loading = false
                this.finished =true
            }
            
        },
        // 分享链接
        shareFriend(){
            if(url.indexOf('?') > -1){
                if(this.pay_status == 0){
                    this.$toast("活动未开始")
                }else{
                    let page_title = '月享1500G全国流量 不限速 不虚标 免插卡'
                    let some_text = '流量在你眼皮底下使用 不敢虚 也虚不了'
                    let page_path = 'pages/buyWifiNew/index?phone='+ cs.phone
                    let website_url = 'https://iots.yunruiot.cominvite?phone=' + cs.phone
                    let image_url = 'https://imgwifi.yunruiot.com/img/shareImg.png'
                    const data = {
                        image: image_url,
                        url: website_url,
                        text: encodeURIComponent(some_text),
                        title: encodeURIComponent(page_title),
                        path: page_path
                    };
                    const jsonString = JSON.stringify(data);
                    const base64Data = btoa(jsonString);
                    let u = navigator.userAgent
                    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
                    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if (isAndroid) {
                        window.Android.OpenInviteFriend(base64Data); // 调用原生app的函数--webClose是安卓提供的方法
                    }
                    if (isIOS) {
                        window.webkit.messageHandlers.OpenInviteFriend.postMessage(base64Data);//--webClose是ios提供的方法
                    }
                }
            }else{
                this.$toast.fail("参数错误")
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .box{
        overflow: hidden;
        width: 100vw;
        background: #EBEBEB;
        height: 100%;
        margin-top: -2px;
        .home{
            width: 100%;
            border: 1px solid transparent;
            position: relative;
            // background: url('@/assets/images/invite/home_bg.png');
            background: url('../../assets/images/invite/home_bg.png') no-repeat;
            background-size: 100%;
            .home_redPack{
                width: 310px;
                height: 300px;
                margin: 160px auto 0px;
                text-align: center;
                background: url('~@/assets/images/invite/redPack.png') no-repeat;
                background-size: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .word{
                    position: absolute;
                    width: 100%;
                    text-align: center;
                    left: 0;
                    top: 122px;
                    font-weight: bold;
                    color: #FEFFE6;
                    text-shadow: 0px 3px 0px #CE9F3F;
                    font-size: 20px;
                }
                .redPack_num{
                    font-size: 18px;
                    font-weight: 800;
                    color: #EA6601;
                    margin-top: 50px;
                    span{
                        font-size: 44px;
                        font-family: 'bahn';
                    }
                }
                .redPack_tip{
                    font-size: 16px;
                    font-weight: bold;
                    color: #FFCCAB;
                    margin-bottom: 60px;
                }
                // img{
                //     height: 100%;
                //     margin: 0 auto;
                // }
            }
            .btn1{
                text-align: center;
                margin: -40px auto 0;
                padding-bottom: 14px;
                width: 286px;
                height: 54px;
                line-height: 54px;
                background: url('~@/assets/images/invite/btn1.png') no-repeat;
                background-size: 100%;
                font-weight: bold;
                font-size: 18px;
                color: #FFFCF9;
                padding-right: 15px;
            }
        }
        .main{
            background: url('~@/assets/images/invite/home_bg2.png') no-repeat;
            background-size: 100%;
            overflow: hidden;
            .getCash{
                background: #FFFFFF;
                box-shadow: 0px 2px 12px 0px rgba(220,60,16,0.72);
                border-radius: 10px;
                margin: 25px 15px;
                overflow: hidden;
                .cashTitle{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    color: #F4751C;
                    font-size: 18px;
                    margin-top: 20px;
                    img{
                        height: 16px;
                        margin: 0px 15px;
                    }
                }
                .cashStep{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 25px 0;
                    .cashStep1{
                        text-align: center;
                        .pic{
                            img{
                                width: 54px;
                                height: 54px;
                            }
                            // .next{
                            //     width: 18px;
                            //     height: 16px;
                            // }
                        }
                        
                        .cashStep1_title{
                            color: #F83F0B;
                            font-weight: bold;
                            margin: 10px 0 8px;
                        }
                        .cashStep1_tip{
                            font-size: 12px;
                            font-weight: 500;
                            color: #9B8F88;
                        }
                    }
                    img{
                        height: 16px;
                        width: 18px;
                    }
                    .next{
                        height: 54px;
                        margin: 0 10px;
                        align-self: flex-start;
                        img{
                            margin-top: 20px;
                        }
                    }
                }
                .shareBtn{
                    border-radius: 18px;
                    width: 140px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto 20px;
                    background: url('~@/assets/images/invite/shareBtn.png') no-repeat;
                    background-size: 100%;
                    color: #FFFFFF;
                    font-weight: 500;
                    font-size: 16px;
                    img{
                        width: 18px;
                        height: 18px;
                        margin-right: 6px;
                    }
                }
            }
        }
        .inviteInfo{
            background: #FDF9F3 !important;
            .invite_num{
                display: flex;
                align-items: center;
                justify-content: space-around;
                .invite_profit{
                    margin: 30px 0;
                    .profit_time{
                        font-weight: 500;
                        color: #FF3E08;
                        font-size: 14px;
                        span{
                            font-size: 40px;
                            font-family: 'bahn';
                        }
                    }
                    .profit_tip{
                        color: #B6B7C1;
                        font-size: 12px;
                        margin-top: 4px;
                    }
                }
                .two{
                    border-left: 1px solid #D4D7E3;
                    padding-left: 20px;
                }
            }
            .invitePeople{
                width: 100%;
                height: 200px;
                background: #fff;
                overflow-y: scroll;
                margin-bottom: 20px;
                border-radius: 10px;
                .inviteBg1{
                     background: #FF5E31;
                     color: #FFFFFF;
                }
                .inviteBg2{
                     background: #FEFCF8;
                     color: #222222;
                }
                .inviteList{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 6px;
                    padding: 10px;
                    margin: 10px;
                    
                    .listLeft{
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        font-size: 17px;
                        img{
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            margin-right: 8px;
                        }
                    }
                    .listRight{
                        color: #FFF8AC;
                        font-size: 12px;
                        font-weight: 400;
                    }
                    .listRightG{
                        color: #D3D5DC;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }
        .btn3{
            width: 229px;
            height: 55px;
            line-height: 45px;
            background: url('~@/assets/images/invite/btn3.png') no-repeat;
            background-size: 100%;
            margin: 14px auto;
            text-align: center;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 20px;
            img{
                width: 8px;
                height: 8px;
            }
        }
        .box2{
            height: 100vh;
            background: #fff;
            div{
                line-height: 100vh;
                text-align: center;
                font-size: 18px;
                font-weight: bold;
            }
        }
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 2px;
        }
    }
</style>